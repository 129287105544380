require('select2/dist/css/select2.min.css');
require('./js/jquery/global/select2');
require('select2/dist/js/i18n/fr');
require("flatpickr");
require('./scss/main.scss');
require('./js/ios-100vh');
require('./js/hyperform-init');
require('./js/hyperform-translation-fr');
require('./js/header-transparent-or-opaque');
require('./js/off-canvas-menu');
require('./js/jquery/moment/moment_form_steps');
require('./js/jquery/moment/moment_form_slider');
require('./js/jquery/project/project_form');
require('./js/jquery/global/confirmations');
require('./js/jquery/global/search-form-switcher');
require('./js/jquery/global/cookies');
require('./js/jquery/project/tabs');
require('./js/jquery/project/manage_select');
require('./js/jquery/notification/add_remove_dates');
require('./js/jquery/user/register');
require('./js/jquery/user/edit');
require('./js/animated-intro');
require('./js/toggle-archived-projects');

if (module.hot) {
    module.hot.accept();
}
