import select2 from 'select2';
$(document).ready(function() {
  $('.select2').select2({
    language: "fr",
    width: "100%"
  });

  const globalSelect = $('#select-global-search')

  if (globalSelect.length) {
    $.ajax({
      url: globalSelect.attr('data-source')
    }).then(function (data) {
      globalSelect.append("<option disabled selected value>Recherche projet/ titre d'un moment</option>");
      if (data['myProjects'].length !== 0) {
        globalSelect.append("<optgroup label='Mes Projets'>")
        data['myProjects'].forEach((project) => {
          globalSelect.append("<option data-type='project' value=\"" + project['id'] + "\">" + project['name'] + "</option>")
        })
        globalSelect.append("</optgroup>")
      }
      if (data['otherProjects'].length !== 0) {
        globalSelect.append("<optgroup label='Autres projets'>");
        data['otherProjects'].forEach((project) => {
          globalSelect.append("<option data-type='project' value=\"" + project['id'] + "\">" + project['name'] + "</option>")
        })
        globalSelect.append("</optgroup>")
      }
      if (data['archivedProjects'].length !== 0) {
        globalSelect.append("<optgroup label='Projets archivés'>");
        data['archivedProjects'].forEach((project) => {
          globalSelect.append("<option data-type='project' value=\"" + project['id'] + "\">" + project['name'] + "</option>")
        })
        globalSelect.append("</optgroup>")
      }
      if (data['myMoments'].length !== 0) {
        globalSelect.append("<optgroup label='Mes Moments'>")
        data['myMoments'].forEach((moment) => {
          globalSelect.append("<option data-type='moment' value=\"" + moment['id'] + "\">" + moment['title'] + "</option>")
        })
        globalSelect.append("</optgroup>")
      }
    });
  }

  globalSelect.change(function(){
    if($(this).find(':selected').data('type') === "project"){
      window.location.href = globalSelect.attr('data-project-destination').replace('id', this.value);
    } else {
      window.location.href = globalSelect.attr('data-moment-destination').replace('id', this.value);
    }
  });
});
