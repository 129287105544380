$(document).ready(function () {
    if (document.getElementById("register-form")) {
        const otherRelationShipType = $("#other_relationship_type_div");
        otherRelationShipType.hide();

        const relationshipTypeSelect = $('#observer_relationshipType');
        relationshipTypeSelect.change(function (e) {
            $("option:selected", this);
            if(this.value === "Autre"){
                otherRelationShipType.show();
            }else{
                $("#observer_otherRelationshipType").val("");
                otherRelationShipType.hide();
            }
        })
    }
});