const archiveToggler = document.querySelector('#toggle-archived-projects');
if(archiveToggler) {
  archiveToggler.addEventListener('click', () => {
    document.querySelectorAll('.project').forEach(project => {
      if (project.classList.contains('archived') ^ archiveToggler.checked) {
        project.classList.add('hidden');
      } else {
        project.classList.remove('hidden');
      }
    });
  });
}