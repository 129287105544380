$(document).ready(function () {
    $(".cookieBtn.cookieDeny").click(function () {
        $.ajax({
            url: $(this).data('url'),
            success: function () {
                $('.cookieBanner').hide();
            }
        });
    });
    $(".cookieBtn.cookieAllow").click(function () {
        $.ajax({
            url: $(this).data('url'),
            success: function () {
                $('.cookieBanner').hide();
            }
        });
    });
})