$(document).ready(function () {
  var searchSwitch = document.querySelector('.search-button');

  var searchForm = document.querySelector('.search-form');

  if (typeof (searchSwitch) != 'undefined' && searchSwitch != null) {

    function toggleSearchForm() {
      isShowingSearchForm() ? hideSearchForm() : showSearchForm();
    }

    function showSearchForm() {
      searchForm.classList.add('show-search-form');
    }

    function hideSearchForm() {
      searchForm.classList.remove('show-search-form');
    }

    function isShowingSearchForm() {
      return searchForm.classList.contains('show-search-form');
    }

    document.querySelector('.search-button').addEventListener('click', function (e) {
      toggleSearchForm();
      e.preventDefault();
    }, false);

  }
});
