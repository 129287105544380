document.addEventListener('DOMContentLoaded', function() {
  const addButton = document.querySelector('#add-date-button');
  const datesContainer = document.querySelector('#dates-container');
  if(datesContainer) {
    addButton.addEventListener('click', function (e) {
      e.preventDefault();

      // Créer un nouveau champ de date
      const index = datesContainer.dataset.index;
      const newForm = createNewDateField(index);

      // Ajouter le nouveau champ à la fin du conteneur
      datesContainer.appendChild(newForm);

      // Mettre à jour l'index pour le prochain champ
      datesContainer.dataset.index = parseInt(index) + 1;
    });

    // Gérer la suppression d'un champ de date
    datesContainer.addEventListener('click', function (e) {
      if (e.target && e.target.matches('.remove-date-button')) {
        e.preventDefault();
        e.target.closest('.date-item').remove();
      }
    });

    // Fonction pour créer un nouveau champ de date
    function createNewDateField(index) {
      const newForm = document.createElement('div');
      newForm.classList.add('date-item', 'form-field');

      const prototype = datesContainer.dataset.prototype;
      newForm.innerHTML = prototype.replace(/__name__/g, index);

      // Ajouter le bouton de suppression
      const removeButton = document.createElement('button');
      removeButton.setAttribute('type', 'button');
      removeButton.classList.add('remove-date-button');
      removeButton.textContent = 'Supprimer';
      newForm.appendChild(removeButton);

      // Ajouter le gestionnaire d'événements pour la suppression
      removeButton.addEventListener('click', function (e) {
        e.preventDefault();
        newForm.remove();
      });

      return newForm;
    }
  }
});

