
//
// The setTimeout is required for flickity
// to make its DOM calculation AFTER
// webpack has done its own job…
//
// For dev: you can get rid of the setTimeout
// when the app has been built in prod ;)
//

setTimeout(function(){
  var Flickity = require('flickity');
  if (!document.getElementsByClassName('intro-carousel').length){
    // class name does not exist in the document
    null;
  }
  else {
    // class exists in the document
    var elem = document.querySelector('.intro-carousel');
    var flkty = new Flickity( elem, {
      cellAlign: 'center',
      contain: true,
      autoPlay: 6000,
      setGallerySize: false,
      prevNextButtons: false,
      pageDots: true,
      pauseAutoPlayOnHover: false,
    });
  }
}, 1000);

// NEW CARROUSEL
setTimeout(function(){
  var Flickity = require('flickity');
  if (!document.getElementsByClassName('intro-carousel-second').length){
    // class name does not exist in the document
    null;
  }
  else {
    // class exists in the document
    var elem = document.querySelector('.intro-carousel-second');
    var flkty = new Flickity( elem, {
      cellAlign: 'center',
      contain: true,
      autoPlay: 6000,
      setGallerySize: false,
      prevNextButtons: true,
      pageDots: false,
      pauseAutoPlayOnHover: false,
    });
  }
}, 1000);


