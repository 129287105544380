$(document).ready(function () {
    let form =document.getElementById("form-moment");
    if(form){
        showStep2();

        document.getElementById("return-to-step1").addEventListener('click', function(e){
            showStep1();
        });
        document.getElementById("return-to-step2").addEventListener('click', function(e){
            showStep2();
        });
        document.getElementById("go-to-step3").addEventListener('click', function(e){
            showStep3();
        });
        document.getElementById("return-to-step3").addEventListener('click', function(e){
            showStep3();
        });
        document.getElementById("go-to-step4").addEventListener('click', function(e){
            showStep4();
        });
        document.getElementById("return-to-step4").addEventListener('click', function(e){
            showStep4();
        });
        document.getElementById("go-to-step5").addEventListener('click', function(e){
            showStep5();
        });

        const helpers = $('.button--help');
        helpers.each(function (index) {
            helpers[index].addEventListener('click', function(e){
                const helper = $('#helper');
                helper.show();
                helper.html(helpers[index].getAttribute('data-type')+helpers[index].getAttribute('data-helper'));
            });
        });
    }


    function  showStep1() {

    }
    function  showStep2() {
        document.getElementById("step2").style.display = '';
        document.getElementById("step3").style.display = 'none';
        document.getElementById("step4").style.display = 'none';
        document.getElementById("step5").style.display = 'none';
        window.dispatchEvent(new Event('resize'));
        $('#helper').hide();
    }
    function  showStep3() {
        document.getElementById("step2").style.display = 'none';
        document.getElementById("step3").style.display = '';
        document.getElementById("step4").style.display = 'none';
        document.getElementById("step5").style.display = 'none';
        $('#helper').hide();
    }

    function  showStep4() {
        document.getElementById("step2").style.display = 'none';
        document.getElementById("step3").style.display = 'none';
        document.getElementById("step4").style.display = '';
        document.getElementById("step5").style.display = 'none';
        $('#helper').hide();
    }

    function  showStep5() {
        document.getElementById("step2").style.display = 'none';
        document.getElementById("step3").style.display = 'none';
        document.getElementById("step4").style.display = 'none';
        document.getElementById("step5").style.display = '';
        $('#helper').hide();
    }

    function setProjectData(url){
        $.ajax({
            url: url
        }).then(function(data) {
            $('#moment_title').attr('placeholder', "Exemple : " + data.momentExampleTitle);
            $('#moment_description').attr('placeholder', "Exemple : " + data.momentExampleDesc);
            $('#moment_difficulties').attr('placeholder', "Exemple : " + data.momentExampleDifficulties);
            $('#moment_tricks').attr('placeholder', "Exemple : " + data.momentExampleTricks);
            $('#project-name').html('Projet : '+ data.name);
            $('#media-helper').attr('data-helper', data.momentExampleAttachmentHelper);
            $('#project-helper').attr('data-helper', data.momentExampleProjectHelper);
            $('#title-helper').attr('data-helper', data.momentExampleTitleHelper);
            $('#time-of-week-helper').attr('data-helper', data.momentExampleTimeOfWeekHelper);
            $('#time-of-day-helper').attr('data-helper', data.momentExampleTimeOfDayHelper);
            $('#location-helper').attr('data-helper', data.momentExampleLocationHelper);
            $('#duration-helper').attr('data-helper', data.momentExampleDurationHelper);
            $('#description-helper').text(data.momentExampleDescHelper);
            $('#difficulties-helper').text(data.momentExampleDifficultiesHelper);
            $('#tricks-helper').text(data.momentExampleTricksHelper);
        });
    }
    function resetProjectData(){
        $('#moment_title').attr('placeholder', "");
        $('#moment_description').attr('placeholder', "");
        $('#moment_difficulties').attr('placeholder', "");
        $('#moment_tricks').attr('placeholder', "");
        $('#project-name').html('Projet : Aucun projet sélectionné');
    }
});
