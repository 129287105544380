const french = require("flatpickr/dist/l10n/fr.js").default.fr;

$(document).ready(function () {
    if(document.getElementById("project-form")){
        let inputProject = document.getElementById("project_attachment_file_file");
        let previewProject = document.getElementById("preview-project");
        addEventListnerForAttachment(inputProject, previewProject);
        $("#project_startDate").flatpickr({
            enableTime: false,
            dateFormat: "d/m/Y",
            "locale": french
        });
        $("#project_endDate").flatpickr({
            enableTime: false,
            dateFormat: "d/m/Y",
            "locale": french
        });
    }

    if(document.getElementById("momentExample-form")){
        let inputMoment = document.getElementById("moment_example_attachment_file_file");
        let previewMoment = document.getElementById("preview-moment");
        addEventListnerForAttachment(inputMoment, previewMoment);
        $("#moment_example_date").flatpickr({
            enableTime: true,
            time_24hr: true,
            dateFormat: "d/m/Y H:i",
            "locale": french,
        });
    }

    const deleteMomentExample = document.getElementById("delete-moment-example");
    if(deleteMomentExample){
        deleteMomentExample.addEventListener("click",() => {
            if(confirm("Êtes vous sur de vouloir supprimer ce moment d'exemple ?")){
                $.ajax({
                    type: "DELETE",
                    url: window.location.origin+"/admin/moment_example/delete/"+$('#select-moment-example').find(":selected").val(),
                    async: true,
                    data: { },
                    success: function (data) {
                        window.location.reload();
                    }
                });
            }
        });
    }

    function addEventListnerForAttachment(input, preview){
        input.addEventListener('change', function(event){
            preview.innerHTML = "";
            var curFiles = input.files;
            var link = document.createElement('a');
            var figure = document.createElement('figure');
            var span1 = document.createElement('span');
            var span2 = document.createElement('span');
            span2.className = "h3-like";
            var para = document.createElement('p');
            if(curFiles.length === 0) {
                para.textContent = 'Pas de fichier sélectionné';
                preview.appendChild(para);
            }else {
                if (validFileType(curFiles[0])) {
                    span2.textContent = "Nouvelle image : " + curFiles[0].name;
                    var image = document.createElement('img');
                    image.src = window.URL.createObjectURL(curFiles[0]);
                    figure.appendChild(image);
                    span1.appendChild(span2);
                    link.appendChild(figure);
                    link.appendChild(span1);
                    preview.appendChild(link);

                } else {
                    para.textContent = 'Type de fichier non valide';
                    preview.appendChild(para);
                }
            }
            event.preventDefault();

        });
    }

    var fileTypes = [
        'image/jpeg',
        'image/pjpeg',
        'image/png'
    ];

    function validFileType(file) {
        for(var i = 0; i < fileTypes.length; i++) {
            if(file.type === fileTypes[i]) {
                return true;
            }
        }
        return false;
    }
});
