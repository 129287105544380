$(document).ready(function () {
   const adminDeleteForm = document.getElementById("delete-admin-form");
    if(adminDeleteForm){
        document.getElementById("delete-admin").addEventListener("click",() => {
            if(confirm("Êtes vous sur de vouloir supprimer cet utilisateur ?")){
                adminDeleteForm.submit();
            }
        });
    }
    const projectDeleteForm = document.getElementById("delete-project-form");
    if(projectDeleteForm){
        document.getElementById("delete-project").addEventListener("click",() => {
            if(confirm("Êtes vous sur de vouloir supprimer ce projet ?")){
                projectDeleteForm.submit();
            }
        });
    }
    const observerDeleteFormAdmin = document.getElementById("delete-observer-form-admin");
    if(observerDeleteFormAdmin){
        document.getElementById("delete-observer").addEventListener("click",() => {
            if(confirm("Êtes vous sur de vouloir supprimer votre compte ?")){
                observerDeleteFormAdmin.submit();
            }
        });
    }
    const observerDeleteForm = document.getElementById("delete-observer-form");
    if(observerDeleteForm){
        document.getElementById("delete-observer").addEventListener("click",() => {
            if(confirm("Êtes vous sur de vouloir supprimer cet utilisateur ?")){
                observerDeleteForm.submit();
            }
        });
    }
    const momentsDeleteForms = $("form[id^=moment-form]");

    if(momentsDeleteForms){
        momentsDeleteForms.each((index) => {
            momentsDeleteForms[index].querySelector(".delete-moment").addEventListener("click",() => {
                if(confirm("Êtes vous sur de vouloir supprimer ce moment ?")){
                    momentsDeleteForms[index].submit();
                }
            });
        });
    }
});
