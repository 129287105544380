var menuSwitch = document.querySelector('.burger-trigger');
var header = document.querySelector('.header-main');

if (typeof(menuSwitch) != 'undefined' && menuSwitch != null) {

  var wholePage = document.querySelector('.whole-page');
  var page = document.querySelector('.page');
  var mask = document.querySelector('.mask');

  function toggleSidebar(){
    isShowingSidebar() ? hideSidebar() : showSidebar();
  }

  function showSidebar(){
    wholePage.classList.add('show-sidebar');
    if (typeof(header) != 'undefined' && header != null) {
      header.classList.add('opaque2');
    }
  }

  function hideSidebar(){
    wholePage.classList.remove('show-sidebar');
    if (typeof(header) != 'undefined' && header != null) {
      header.classList.remove('opaque2');
    }
  }

  function isShowingSidebar(){
    return wholePage.classList.contains('show-sidebar');
  }

  document.querySelector('.burger-trigger').addEventListener('click', function(e) {
    toggleSidebar();
    e.preventDefault();
  }, false);

  wholePage.addEventListener('click', function(e){
    if(isShowingSidebar() && (page.contains(e.target) || mask.contains(e.target))){
      e.preventDefault();
      hideSidebar();
    }
  }, true);

}