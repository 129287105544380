var header = document.querySelector('.header-main');
if (typeof(header) != 'undefined' && header != null) {
  function add_class_on_scroll() {
    header.classList.add("opaque");
  }
  function remove_class_on_scroll() {
    header.classList.remove("opaque");
  }
  window.onscroll = function(){
    if (window.scrollY > 10) {
      add_class_on_scroll();
    } else {
      remove_class_on_scroll();
    }
  }
}