let inputIndex = 0;
let sizeOfFilesToUpload = [];
var jQueryBridget = require('jquery-bridget');
var Flickity = require('flickity');
// make Flickity a jQuery plugin
jQueryBridget('flickity', Flickity, $);
const MAX_UPLOAD_SIZE = 100000000; // 100Mo
const MAX_SIZE_BY_IMAGE = 10000000; // 10Mo
const MAX_SIZE_BY_VIDEO = 50000000;

$(document).ready(function () {
    let form = document.getElementById("form-moment");
    if (form) {
        const isEditForm = window.location.pathname.includes('moment/edit');
        let indexOfExistantMedia = [];
        const $carousel = $('.carousel').flickity({
            draggable: true, pageDots: false, wrapAround: true
        });
        let divInitial = document.getElementById("moment_attachments_initial");
        let carousel = document.getElementById("carousel");

        let drop = document.getElementById("drop");
        let commands = document.getElementById("commands");
        let add = document.getElementById("slider-add");

        if (isEditForm && document.getElementById("moment_attachments").children.length !== 0) {
            let actualMedias = document.getElementById("moment_attachments");
            for (let media of actualMedias.children) {
                media.style.display = 'none';
                const urlMedia = window.location.origin + media.getElementsByTagName('a')[0].getAttribute('href');
                const fileType = (['jpg', 'png', 'jpeg'].includes(urlMedia.split('.').pop().toLowerCase())) ? "img" : "video";
                let newMedia = createDivCarouselCell(urlMedia, fileType);
                $carousel.flickity('append', newMedia);
                indexOfExistantMedia.push('index' + inputIndex);
                inputIndex++;
            }
            let newInput = createNewInputFromIndex();
            let newLabel = createNewLabelFromIndex();
            add.appendChild(newInput);
            add.appendChild(newLabel);
            window.dispatchEvent(new Event('resize'));
        } else {
            createFirstInput();
        }


        drop.addEventListener('click', function (event) {
            event.preventDefault();
            if(confirm("Êtes vous sur de vouloir supprimer ce média ?")) {
                const cellToDrop = $('.carousel-cell.is-selected');
                const indexToDrop = cellToDrop.children().data('index');

                if (isEditForm && indexOfExistantMedia.includes('index' + indexToDrop)) {
                    document.getElementById('moment_attachments_' + indexToDrop + '_file_file').remove();
                    document.getElementById('moment_attachments_' + indexToDrop + '_file_delete').setAttribute('checked', 'checked');
                    for (let i = 0; i < indexOfExistantMedia.length; i++) {
                        if (indexOfExistantMedia[i] === 'index' + indexToDrop) {
                            indexOfExistantMedia.splice(i, 1);
                        }
                    }
                } else {
                    document.getElementById(generateIdFromIndex(indexToDrop)).remove();
                    document.getElementById("label_" + generateIdFromIndex(indexToDrop)).remove();
                }
                if (sizeOfFilesToUpload["f" + indexToDrop]) {
                    delete sizeOfFilesToUpload["f" + indexToDrop];
                }
                $carousel.flickity('remove', cellToDrop);

                if ($carousel.flickity('getCellElements').length === 0) {
                    divInitial.style.display = '';
                    divInitial.innerHTML = '';
                    add.innerHTML = '';
                    inputIndex = 0;
                    createFirstInput();
                }
            }
        });

        form.onsubmit = function () {
            //Disable last input to avoid empty data
            document.getElementById(generateIdFromIndex(inputIndex)).setAttribute('disabled', 'disabled');
            return true;
        };

        function createFirstInput() {
            //Hide carousel
            carousel.style.display = 'none';
            drop.style.display = 'none';
            commands.style.display = 'none';

            //Create first input
            let div = newInput();
            divInitial.appendChild(div);

            let firstInput = document.getElementById("moment_attachments_0_file_file");
            firstInput.addEventListener('change', function (event) {
                let files = firstInput.files;
                if (files.length === 0) {
                    divInitial.style.display = '';
                    carousel.style.display = 'none';
                    drop.style.display = 'none';
                    commands.style.display = 'none';
                } else {
                    const fileType = validFile(files[0]);
                    if (fileType) {
                        //If file selected : hide first input and show carousel
                        divInitial.style.display = 'none';
                        carousel.style.display = '';
                        drop.style.display = '';
                        commands.style.display = '';
                        // Create first media (from selected file) in carousel
                        let newMedia = createDivCarouselCell(window.URL.createObjectURL(files[0]), fileType);
                        $carousel.flickity('append', newMedia);
                        inputIndex++;
                        // Create next input (and label) in carousel to add more file
                        let newInput = createNewInputFromIndex();
                        let newLabel = createNewLabelFromIndex();
                        add.appendChild(newInput);
                        add.appendChild(newLabel);
                        window.dispatchEvent(new Event('resize'));
                    }
                }
            });
        }

        function newInput() {
            let div = document.createElement('div');
            let inputFile = document.createElement('input');
            let label = document.createElement('label');
            let span1 = document.createElement('span');
            let span2 = document.createElement('span');
            let a = document.createElement('a');

            inputFile.setAttribute('type', 'file');
            inputFile.setAttribute('id', 'moment_attachments_0_file_file');
            inputFile.setAttribute('name', 'moment[attachments][0][file][file]');
            inputFile.setAttribute('class', 'input-file');

            label.setAttribute("class", "input-media");
            label.setAttribute("for", "moment_attachments_0_file_file");
            label.setAttribute("id", "label_moment_attachments_0_file_file");
            label.textContent = "Photo/Vidéo";


            a.setAttribute("id", "media-helper");
            a.setAttribute("role", "button");
            a.setAttribute("href", "#");
            a.setAttribute("data-toggle", "collapse");
            a.setAttribute("data-target", ".moment_attachments_file_file");
            a.setAttribute("data-type", "Photo/Vidéo : ");
            a.setAttribute("data-helper", "Prenez une photo ou une vidéo de la situation. Lorsque vous prenez un moment en photo, vous n’êtes pas obligé d’être sur la photo. Nous ne voulons en aucun cas entrer dans votre intimité, simplement visualiser et contextualiser les scènes que vous nous décrivez. Prenez en photo ce qui vous parait être le plus représentatif de ce dont vous parlez.");
            a.setAttribute("class", "button--help");
            a.textContent = "?";
            a.addEventListener('click', function(e){
                const helper = $('#helper');
                helper.show();
                helper.html(a.getAttribute('data-type')+a.getAttribute('data-helper'));
            });

            div.appendChild(inputFile);
            label.appendChild(span1);
            label.appendChild(a);
            div.appendChild(label);
            return div;
        }

        function createDivCarouselCell(urlMedia, mediaType) {
            const inlineForcedWidthHeight = "width: 40rem; max-width: calc(100vw - 2rem); height: 40rem; max-height: calc(100vw - 2rem);";
            let div = document.createElement('div');
            let divMedia = document.createElement('div');
            divMedia.setAttribute('data-index', inputIndex.toString());
            div.setAttribute('class', "carousel-cell");
            if (mediaType === 'img') {
                divMedia.setAttribute('class', "img");
                divMedia.setAttribute('style', 'background-image: url(' + urlMedia + '); ' + inlineForcedWidthHeight)
            }else{
                divMedia.setAttribute('class', "video");
                let divVideo = document.createElement('video');
                divVideo.setAttribute("controls", "");
                let divSource = document.createElement('source');
                divSource.setAttribute("src", urlMedia);
                divVideo.appendChild(divSource);
                divMedia.appendChild(divVideo);
            }
            div.appendChild(divMedia);
            return div;
        }

        function createNewInputFromIndex() {
            let input = document.createElement('input');
            input.setAttribute('id', generateIdFromIndex(inputIndex));
            input.setAttribute('name', generateNameFromIndex());
            input.setAttribute('class', "input-file");
            input.setAttribute('type', "file");
            input.setAttribute('data-index', inputIndex.toString());


            input.addEventListener('change', function (event) {
                let files = input.files;
                if (files.length === 0) {
                } else {
                    const fileType = validFile(files[0]);
                    if(fileType) {
                        // If file selected, hide input and label
                        let lastInput = document.getElementById(generateIdFromIndex(inputIndex));
                        let lastLabel = document.getElementById("label_" + generateIdFromIndex(inputIndex));
                        lastInput.style.display = 'none';
                        lastLabel.style.display = 'none';
                        let carousel = document.getElementById("carousel");
                        // Create new media in carousel
                        let newMedia = createDivCarouselCell(window.URL.createObjectURL(files[0]), fileType);
                        $carousel.flickity('append', newMedia);
                        $carousel.flickity( 'next', true, false);
                        inputIndex++;
                        // And create new input for next file
                        let newInput = createNewInputFromIndex();
                        let newLabel = createNewLabelFromIndex();
                        let add = document.getElementById("slider-add");
                        add.appendChild(newInput);
                        add.appendChild(newLabel);
                        window.dispatchEvent(new Event('resize'));
                    }
                }

            });

            return input;
        }

        function createNewLabelFromIndex() {
            let label = document.createElement('label');
            let span = document.createElement('span');
            label.setAttribute("for", generateIdFromIndex(inputIndex))
            label.setAttribute("id", "label_" + generateIdFromIndex(inputIndex))
            span.setAttribute('class', 'hide-me');
            span.textContent = "Photo/Vidéo";
            label.appendChild(span);
            return label;
        }

        function generateIdFromIndex(index) {
            return 'moment_attachments_' + index + '_file_file';
        }

        function generateNameFromIndex() {
            return 'moment[attachments][' + inputIndex + '][file][file]';
        }

        const imageFileTypes = [
            'image/jpeg',
            'image/pjpeg',
            'image/png'
        ];

        const videoFileTypes = [
            'video/mp4',
            'video/webm',
            'video/ogg',
            'video/avi',
            'video/quicktime'
        ];

        function validFile(file) {
            if(imageFileTypes.includes(file.type)){
                if(file.size < MAX_SIZE_BY_IMAGE && hasReachedMaxSizeUpload(file.size)){
                    sizeOfFilesToUpload["f"+inputIndex.toString()] = file.size;
                    return "img";
                }
                alert("Fichier ou total des fichiers importés trop volumineux (10Mo max par photos et 100Mo au total)");
            }
            if(videoFileTypes.includes(file.type)){
                if(file.size < MAX_SIZE_BY_VIDEO && hasReachedMaxSizeUpload(file.size)) {
                    sizeOfFilesToUpload["f"+inputIndex.toString()] = file.size;
                    return "video";
                }
                alert("Fichier ou total des fichiers importés trop volumineux (50Mo max par vidéo et 100Mo au total)");
            }
            alert("Ce type de media n'est pas pris en compte");
            return false;
        }

        function hasReachedMaxSizeUpload(newSize){
            return newSize + sizeOfFilesToUpload.reduce((a,b) => a + b,0) < MAX_UPLOAD_SIZE;
        }
    }
});
