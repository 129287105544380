document.addEventListener('DOMContentLoaded', function() {
  // Function to add a new ProjectMomentOption
  function addProjectMomentOption(selectContainer) {
    const listOptionsContainer = selectContainer.querySelector('.select-option-list')

    const valueId = "project_selects_" + selectContainer.dataset.index + "_options_" + listOptionsContainer.dataset.index + "_name";
    const valueName = "project[selects][" + selectContainer.dataset.index + "][options][" + listOptionsContainer.dataset.index + "][name]";
    const rankId = "project_selects_" + selectContainer.dataset.index + "_options_" + listOptionsContainer.dataset.index + "_optionRank";
    const rankName = "project[selects][" + selectContainer.dataset.index + "][options][" + listOptionsContainer.dataset.index + "][optionRank]";

    const prototype = "" +
      "<div class=\"list-project-moment-option moment-option-container\" >" +
      "<div class=\"list-project-moment-option\">" +
      "<label>Valeur</label>" +
      "<input type=\"text\" id=\"" + valueId + "\" name=\" " + valueName + "\" required=\"required\">" +
      "</div>" +
      "<div class=\"list-project-moment-option\">" +
      "<label>Rang</label>" +
      "<input type=\"number\" id=\"" + rankId + "\" name=\"" + rankName + "\" required=\"required\">" +
      "</div>" +
      "<button type=\"button\" class=\"remove-button-admin remove-option-button\">Supprimer le choix</button>" +
      "</div>";

    listOptionsContainer.dataset.index++;
    const newOption = document.createElement('div');
    newOption.className = 'list-project-moment-option moment-option-container';
    newOption.innerHTML = prototype;
    listOptionsContainer.appendChild(newOption);

    // Add event listener to the new remove button
    newOption.querySelector('.remove-button-admin').addEventListener('click', function () {
      newOption.remove();
    });
  }

  // Function to add a new ProjectMomentSelect
  function addProjectMomentSelect() {
    const prototype = document.getElementById('selects-management').dataset.prototype.replace(/__name__/g, index);
    index++;
    const newSelect = document.createElement('div');
    newSelect.classList.add('select-container');
    newSelect.innerHTML = prototype;
    document.getElementById('selects-management').appendChild(newSelect);

    // Add event listener to the new remove button
    newSelect.querySelector('.remove-button-admin').addEventListener('click', function() {
      newSelect.remove();
    });

    // Add event listener to the add option button
    newSelect.querySelector('.add-button-admin').addEventListener('click', function() {
      addProjectMomentOption(newSelect.querySelector('.select-option-list'));
    });

    // Initialize the index for options
    newSelect.querySelector('.select-option-list').dataset.index = 0;
  }

  // Initialize the index for selects
  let index = document.querySelectorAll('.select-container').length;

  // Add event listener to the add select button
  // document.getElementById('add-select').addEventListener('click', addProjectMomentSelect);

  document.querySelectorAll('.remove-select-button').forEach(function(button) {
    button.addEventListener('click', function() {
      button.closest('.select-container').remove();
    });
  });

  document.querySelectorAll('.remove-option-button').forEach(function(button) {
    button.addEventListener('click', function() {
      button.closest('.moment-option-container').remove();
    });
  });

  // Add event listeners to existing add option buttons
  document.querySelectorAll('.add-select-option').forEach(function(button) {
    button.addEventListener('click', function() {
      addProjectMomentOption(button.closest('.select-container'));
    });
  });
});